<div class="container service-catalog">
  <div class="search-row">
    <div class="a-text-field a-text-field--search full-width">
      <input
        [formControl]="searchControl"
        type="text"
        id="12"
        placeholder="{{ 'interface.search' | translate }}"
        class="bsh-light"
        (keyup)="searchService($event)"
      />
      <button
        type="submit"
        class="a-text-field__icon-search"
      >
        <em
          class="a-icon ui-ic-search"
          title="LoremIpsum"
        ></em>
      </button>
    </div>
  </div>

  <div class="a-tile catalogue-container">
    <ng-container
      *ngFor="let item of filteredItems; trackBy: servicesTrackByFn"
    >
      <div class="box">
        <div class="d-inline-flex col-align">
          <div class="row-img">
            <img
              class="app-icon-img"
              [ngClass]="{ 'alt-image': brokenImage }"
              [src]="item?.logo"
              alt="No Logo"
              (error)="updateUrl($event)"
            />
            <div class="col-title">
              <div class="app-sub-title">
                {{ item?.company }}
              </div>
              <div
                class="app-title"
                title="{{ item.title }}"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="app-info"
              title="{{ item.shortDescription }}"
            >
              {{ item.shortDescription }}
            </div>
            <div class="row">
              <a
                [routerLink]="[serviceDetailsURL, item.serviceId]"
                class="open-application {{ item.serviceId }}"
              >
                <p>View Details</p>
                <em
                  class="a-icon boschicon-bosch-ic-forward-right"
                  title="Open application"
                >
                </em>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
