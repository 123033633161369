import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { AppState } from '../../../shared/data/state/app.state.model';
import { observeFetchSuccess } from '../../../shared/util';
import { IVehicleListDigitalAuto } from '../models';
import { LoadVehiclesDigitalAuto } from '../state';

export const userVehicleListingPageDataResolverDigitalAuto: ResolveFn<
  IVehicleListDigitalAuto
> = async (__: ActivatedRouteSnapshot, _: RouterStateSnapshot) => {
  const store = inject(Store) as Store<AppState>;
  store.dispatch(new LoadVehiclesDigitalAuto());

  return await firstValueFrom(
    observeFetchSuccess(store.select((state) => state.digitalAuto.vehicles))
  );
};
