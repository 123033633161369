import { CommonModule, NgFor, NgForOf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-tab-navigation',
  standalone: true,
  imports: [NgFor, NgForOf, CommonModule],
  templateUrl: './tab-navigation.da.component.html',
  styleUrl: './tab-navigation.da.component.scss',
})
export class TabNavigationComponentDigitalAuto
  implements OnInit, AfterViewInit
{
  @Input() tabItems: string[] | undefined;
  @Input() currentItem: number | undefined;
  @Output() selectItem = new EventEmitter<number>();

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const defaultTab = document.querySelectorAll('.a-tab-navigation__item')[
      this.currentItem ?? 0
    ];
    defaultTab.classList.add('-selected');
  }

  onTabClick(index: number): void {
    const tabs = document.querySelectorAll('.a-tab-navigation__item');
    tabs.forEach((element) => {
      element.classList.remove('-selected');
    });
    tabs[index].classList.add('-selected');
    this.selectItem.emit(index);
  }
}
