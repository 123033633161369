import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom, map, tap } from 'rxjs';
import { shortUrls } from 'src/app/app-urls';
import { AppState } from '../../../../shared/data/state/app.state.model';
import {
  observeFetchSuccess,
  subscribeAndReturnObservable,
} from '../../../../shared/util';
import { OrderSuccessDialogComponentDigitalAuto } from '../../components';
import { IOrderSummary } from '../../models';
import { visaSVGImageUrl } from '../../resources';
import {
  PlaceOrderDigitalAuto,
  ResetSubscriptionFlowDigitalAuto,
  ResetPlaceOrderProgressDigitalAuto,
} from '../../state';
import { ErrorMessageComponent } from '../../components/error-message/error-message.component';

@Component({
  selector: 'app-order-summary',
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    DatePipe,
    AsyncPipe,
    OrderSuccessDialogComponentDigitalAuto,
    ErrorMessageComponent,
  ],
  templateUrl: './order-summary.da.component.html',
  styleUrl: './order-summary.da.component.scss',
})
export class OrderSummaryComponentDigitalAuto implements OnInit {
  showOrderSuccessDialog$ = new BehaviorSubject<boolean>(false);
  showOrderErrorMessage$ = new BehaviorSubject<boolean>(false);
  placeOrderErrorMessage =
    'Order failed. Please check your place order details and try again.';

  order: IOrderSummary | undefined;
  subscriptionId: string | undefined;

  orderPlacement$ = subscribeAndReturnObservable(
    observeFetchSuccess(
      this.store.select(
        (state) => state.digitalAuto.subscriptionFlow.orderPlacement
      )
    ).pipe(
      tap((order) => {
        this.subscriptionId = order.subscriptionId;
        this.showOrderSuccessDialog$.next(true);
      })
    )
  );

  readonly visaSVGImageUrl = visaSVGImageUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.resetOrderProgress();
    this.loadOrderSummary();
    this.subscribeToOrderPlacementProgress();
  }

  private resetOrderProgress() {
    this.store.dispatch(new ResetPlaceOrderProgressDigitalAuto());
  }

  private loadOrderSummary() {
    this.activatedRoute.data
      .pipe<IOrderSummary>(map(({ orderSummary }) => orderSummary))
      .subscribe((value) => {
        this.order = value;
      });
  }

  private subscribeToOrderPlacementProgress() {
    this.store
      .select(
        (state) => state.digitalAuto.subscriptionFlow.orderPlacement.progress
      )
      .pipe(
        tap((progress) => {
          if (progress === 'dataFetchFailed') {
            this.showOrderErrorMessage$.next(true);
          }
        })
      )
      .subscribe();
  }

  placeOrder() {
    if (!this.order) return;

    this.store.dispatch(
      new PlaceOrderDigitalAuto({
        planId: this.order.plan.planId!,
        serviceId: this.order.service.serviceId!,
        vin: this.order.vehicle.vin!,
      })
    );
  }

  async closeDialog() {
    this.showOrderSuccessDialog$.next(false);
    const order = await firstValueFrom(this.orderPlacement$);
    this.store.dispatch(new ResetSubscriptionFlowDigitalAuto());
    this.router.navigate(
      [
        `../../../../${shortUrls.vehicles}/${order.vehicle.vin}/${shortUrls.services}`,
      ],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          currentLevel: 98,
        },
      }
    );
  }
}
