import { addMinutes, format } from 'date-fns';

function generateStuttgartMapLocation(): {
  locationUrl: string;
  locationLabel: string;
} {
  // Generate random coordinates within Germany bounds
  const randomLat = (Math.random() * (48.84 - 48.7) + 48.7).toFixed(6);
  const randomLng = (Math.random() * (9.16 - 9.04) + 9.04).toFixed(6);

  // Google Maps URL template with the random coordinates
  const locationUrl = `https://www.google.com/maps?q=${randomLat},${randomLng}&z=8`;

  // Create a label for the location
  const locationLabel = `${randomLat}, ${randomLng}`;

  return { locationUrl, locationLabel };
}

// Type for the header item
type TableHeader = {
  key: string;
  title: string;
};

// Extract keys from TableHeader as a union type
type TableKeys = TableHeader['key']; // 'vehicle' | 'eventType' | 'timestamp' | 'location'

// Type for the table data item
type TableDataItem = {
  [key in TableKeys]: any;
};

// Type for the entire table data array
type TableData = TableDataItem[];

const eventHistoryTableHeaders: TableHeader[] = [
  { key: 'vehicle', title: 'Vehicle' },
  { key: 'eventType', title: 'Event type' },
  { key: 'timestamp', title: 'Timestamp' },
  { key: 'locationLabel', title: 'Location' },
];

let previousTimestamp = new Date();
export const eventHistoryTableMockDigitalAuto: {
  headers: TableHeader[];
  data: TableData;
} = {
  headers: eventHistoryTableHeaders,
  data: [
    {
      vehicle: 'Family car',
      eventType: 'Low level detected',
    },
    {
      vehicle: 'Family car',
      eventType: 'Replenishment ordered',
    },
    {
      vehicle: 'Family car',
      eventType: 'Replenishment delivered',
    },
    {
      vehicle: 'Family car',
      eventType: 'Tank refilled',
    },
    {
      vehicle: 'Business car',
      eventType: 'Low level detected',
    },
    {
      vehicle: 'Business car',
      eventType: 'Replenishment ordered',
    },
    {
      vehicle: 'Business car',
      eventType: 'Replenishment delivered',
    },
    {
      vehicle: 'Business car',
      eventType: 'Tank refilled',
    },
    {
      vehicle: 'Business car',
      eventType: 'Low level detected',
    },
    // Add more entries as needed
  ].map((entry) => {
    const randomAdjustment = Math.floor(Math.random() * (4 * 60 - 30 + 1)) + 30;

    // Apply the random adjustment to the previous timestamp
    const adjustedTimestamp = addMinutes(previousTimestamp, -randomAdjustment);

    // Update previousTimestamp for the next iteration
    previousTimestamp = adjustedTimestamp;
    return {
      ...entry,
      ...generateStuttgartMapLocation(),
      timestamp: format(adjustedTimestamp, 'dd MMM yyyy HH:mm'),
    };
  }),
};
