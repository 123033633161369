import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AppState } from '../../../../../shared/data/state/app.state.model';
import {
  IServiceDetailsDigitalAuto,
  IServicePlanPricingDigitalAuto,
} from '../../../models';
import {
  LoadVehiclesDigitalAuto,
  SetPlanIdForSubscriptionFlowDigitalAuto,
  SetServiceIdForSubscriptionFlowDigitalAuto,
  SetVehicleIdForSubscriptionFlowDigitalAuto,
} from '../../../state/actions';
import { ChooseVehicleDialogComponentDigitalAuto } from '../../../components/choose-vehicle-dialog/choose-vehicle-dialog.da.component';
import { ContainerDialogComponent } from '../../../../../components';
import { ErrorMessageComponent } from '../../../components/error-message/error-message.component';
import { SystemBannerComponent } from '../../../../../components';
import { ActivatedRoute, Router } from '@angular/router';
import { shortUrls } from '../../../../../app-urls';
import { SubscriptionServiceDigitalAuto } from '../../../services/subscription.da.service';

@Component({
  selector: 'app-plans-pricing',
  standalone: true,
  templateUrl: './plans-pricing.da.component.html',
  styleUrl: './plans-pricing.da.component.scss',
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslateModule,
    ContainerDialogComponent,
    ChooseVehicleDialogComponentDigitalAuto,
    ErrorMessageComponent,
    SystemBannerComponent,
  ],
})
export class PlansPricingComponentDigitalAuto implements OnInit {
  showVehicleSelectionDialog$ = new BehaviorSubject<boolean>(false);
  showNoStockErrorMessage$ = new BehaviorSubject<boolean>(false);
  noStockErrorMessage =
    'There are currently no stocks available for this product.';

  @Input() serviceDetails: IServiceDetailsDigitalAuto | undefined | null =
    undefined;

  selectedPlan: IServicePlanPricingDigitalAuto | null = null;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private subscriptionService: SubscriptionServiceDigitalAuto
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadVehiclesDigitalAuto());
  }

  onVehicleSelection(selectedVehicleId: string) {
    this.store.dispatch(
      new SetServiceIdForSubscriptionFlowDigitalAuto(
        this.serviceDetails?.serviceId!
      )
    );
    this.store.dispatch(
      new SetVehicleIdForSubscriptionFlowDigitalAuto(selectedVehicleId)
    );

    this.showVehicleSelectionDialog$.next(false);

    this.router.navigate(
      [
        `../../${shortUrls.orderSummary}/${this.serviceDetails?.serviceId}/${this.selectedPlan?.planId}/${selectedVehicleId}`,
      ],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  selectPlan(plan: IServicePlanPricingDigitalAuto) {
    this.selectedPlan = plan;

    this.subscriptionService.checkStockAvailability().subscribe((response) => {
      if (response) {
        this.store.dispatch(
          new SetPlanIdForSubscriptionFlowDigitalAuto(plan.planId)
        );
        this.showVehicleSelectionDialog$.next(true);
      } else {
        this.showNoStockErrorMessage$.next(true);
      }
    });
  }
}
