<div class="container">
  <div class="a-tile tile-container">
    <div class="box borderless-box">
      <div class="d-inline-flex col-align">
        <div class="row-img">
          <img
            class="app-icon-img"
            [ngClass]="{ 'alt-image': brokenImage }"
            [src]="(serviceDetails$ | async)?.logo"
            alt="No Logo"
            (error)="updateUrl($event)"
          />
          <div class="col-title">
            <div class="app-sub-title">
              {{ (serviceDetails$ | async)?.company }}
            </div>
            <div
              class="app-title"
              title="{{ (serviceDetails$ | async)?.title }}"
            >
              {{ (serviceDetails$ | async)?.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-tab-navigation
    [tabItems]="tabItems"
    [currentItem]="currentItem"
    (selectItem)="selectItem($event)"
  >
  </app-tab-navigation>

  @switch (currentItem) {
    @case (0) {
      <app-product-details
        [serviceDetails]="serviceDetails$ | async"
      ></app-product-details>
    }
    @case (1) {
      <app-highlights
        [serviceDetails]="serviceDetails$ | async"
      ></app-highlights>
    }
    @case (2) {
      <app-plans-pricing
        [serviceDetails]="serviceDetails$ | async"
      ></app-plans-pricing>
    }
  }
</div>
