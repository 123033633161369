import { Action } from '@ngrx/store';
import { IProgressDigitalAuto, IUserDigitalAuto } from '../../models';

export const LOAD_USER_DELIVERY_INFO_DIGITAL_AUTO: string =
  '[Digital Auto] Load User Delivery Info';

export class LoadUserDeliveryInfoDigitalAuto implements Action {
  readonly type = LOAD_USER_DELIVERY_INFO_DIGITAL_AUTO;
  constructor(public payload: void) {}
}

export const SET_USER_DELIVERY_INFO_PROGRESS_DIGITAL_AUTO: string =
  '[Digital Auto] Set User Delivery Info Loading State';

export class SetUserDeliveryInfoProgressDigitalAuto implements Action {
  readonly type = SET_USER_DELIVERY_INFO_PROGRESS_DIGITAL_AUTO;
  constructor(public payload: IProgressDigitalAuto) {}
}

export const SET_USER_DELIVERY_INFO_DIGITAL_AUTO: string =
  '[Digital Auto] Set User Delivery Info';

export class SetUserDeliveryInfoDigitalAuto implements Action {
  readonly type = SET_USER_DELIVERY_INFO_DIGITAL_AUTO;
  constructor(public payload: IUserDigitalAuto) {}
}

export type IUserActionsDigitalAuto =
  | LoadUserDeliveryInfoDigitalAuto
  | SetUserDeliveryInfoProgressDigitalAuto
  | SetUserDeliveryInfoDigitalAuto;
