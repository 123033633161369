import { subscribeAndReturnObservable } from '../../../../shared/util/observables-helper.util';
import { Component } from '@angular/core';
import { GrayBoxComponent } from '../../../../components';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { IVehicleListDigitalAuto } from '../../models';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { shortUrls } from '../../../../app-urls';

@Component({
  selector: 'app-user-vehicle-listing-page',
  standalone: true,
  imports: [GrayBoxComponent, FlexLayoutModule, AsyncPipe],
  templateUrl: './user-vehicle-listing-page.da.component.html',
  styleUrl: './user-vehicle-listing-page.da.component.scss',
})
export class UserVehicleListingPageComponentDigitalAuto {
  vehicles$ = subscribeAndReturnObservable<IVehicleListDigitalAuto>(
    this.activatedRoute.data.pipe(map(({ vehicles }) => vehicles))
  );
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  viewServices(vin: string) {
    this.router.navigate([`${vin}/${shortUrls.services}`], {
      relativeTo: this.activatedRoute,
    });
  }
}
