import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { AppState } from '../../../shared/data/state/app.state.model';
import { observeFetchSuccess } from '../../../shared/util';
import {
  IServiceListingForVehicleRouteData,
  IVehicleDigitalAuto,
  IVehicleSubscriptionList,
} from '../models';

import {
  LoadVehicleInfoDigitalAuto,
  LoadVehicleSubscriptionDigitalAuto,
} from '../state';

export const serviceListingForVehiclePageDataResolverDigitalAuto: ResolveFn<
  IServiceListingForVehicleRouteData
> = async (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
  _: RouterStateSnapshot
) => {
  const vin = activatedRouteSnapshot.paramMap.get('vehicleId') as string;
  const store = inject(Store) as Store<AppState>;
  let vehicleData = await firstValueFrom(
    store.select((state) => state.digitalAuto.vehicleSubscriptions[vin])
  );
  if (!vehicleData?.subscriptions?.data?.length) {
    store.dispatch(new LoadVehicleSubscriptionDigitalAuto(vin));
  }

  if (!vehicleData?.vehicle?.data?.id) {
    store.dispatch(new LoadVehicleInfoDigitalAuto(vin));
  }

  return {
    vehicle: await firstValueFrom(
      observeFetchSuccess<IVehicleDigitalAuto>(
        store.select(
          (state) => state.digitalAuto.vehicleSubscriptions[vin]?.vehicle || {}
        )
      )
    ),
    subscriptions: await firstValueFrom(
      observeFetchSuccess<IVehicleSubscriptionList>(
        store.select(
          (state) =>
            state.digitalAuto.vehicleSubscriptions[vin]?.subscriptions || []
        )
      )
    ),
  };
};
