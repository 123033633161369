import { Component } from '@angular/core';
import { eventHistoryTableMockDigitalAuto } from '../../resources';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../shared/data/state/app.state.model';
import { LoadOrderListDigitalAuto } from '../../state';
import {
  observeFetchSuccess,
  subscribeAndReturnObservable,
} from '../../../../shared/util';
import { map } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-event-history',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './event-history.da.component.html',
})
export class EventHistoryPageDigitalAuto {
  tableData$ = subscribeAndReturnObservable(
    observeFetchSuccess(
      this.store.select((state) => state.digitalAuto.orders)
    ).pipe(
      map((orders) => {
        const vehicles = orders.map((o) => o.vehicle.name.toLowerCase());
        return {
          ...eventHistoryTableMockDigitalAuto,
          data: eventHistoryTableMockDigitalAuto.data.filter((d) =>
            vehicles.includes(d['vehicle'].toLowerCase())
          ),
        };
      })
    )
  );
  constructor(private store: Store<AppState>) {
    this.store.dispatch(new LoadOrderListDigitalAuto());
  }
}
