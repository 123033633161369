import { IServiceDetailsDigitalAuto } from './../../models/service.da.model';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/data/state/app.state.model';
import {
  observeFetchSuccess,
  subscribeAndReturnObservable,
} from '../../../../shared/util';
import { TabNavigationComponentDigitalAuto } from '../../components/tab-navigation/tab-navigation.da.component';
import { ChooseVehicleDialogComponentDigitalAuto } from '../../components/choose-vehicle-dialog/choose-vehicle-dialog.da.component';
import { HighlightsComponentDigitalAuto } from './highlights/highlights.da.component';
import { PlansPricingComponentDigitalAuto } from './plans-pricing/plans-pricing.da.component';
import { ProductDetailsComponentDigitalAuto } from './product-details/product-details.da.component';
import { map } from 'rxjs';

@Component({
  selector: 'app-service-details',
  standalone: true,
  imports: [
    CommonModule,
    TabNavigationComponentDigitalAuto,
    HighlightsComponentDigitalAuto,
    PlansPricingComponentDigitalAuto,
    ProductDetailsComponentDigitalAuto,
    ChooseVehicleDialogComponentDigitalAuto,
  ],
  templateUrl: './service-details.da.component.html',
  styleUrl: './service-details.da.component.scss',
})
export class ServiceDetailsComponentDigitalAuto {
  brokenImage = false;
  @Input() data: any;
  @Input() serviceId: string | undefined;
  currentItem = 0;
  tabItems = ['Product Details', 'Highlights', 'Plans and Pricing'];

  serviceDetails$ = subscribeAndReturnObservable(
    this.activatedRoute.data.pipe(map(({ service }) => service))
  );

  constructor(private activatedRoute: ActivatedRoute) {}

  updateUrl(validateImage: { type: string }) {
    if (validateImage.type === 'error') {
      this.brokenImage = true;
    }
  }

  selectItem(selectedIndex: number) {
    this.currentItem = selectedIndex;
  }
}
