import { AsyncPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, firstValueFrom, startWith, Observable } from 'rxjs';
import {
  observeFetchSuccess,
  subscribeAndReturnObservable,
} from 'src/app/shared/util';

import { Store } from '@ngrx/store';
import { ButtonComponent } from '../../../../components';
import { AppState } from '../../../../shared/data/state/app.state.model';
import { IOrderDigitalAuto, IOrderListDigitalAuto } from '../../models';
import {
  LoadOrderListDigitalAuto,
  UnsubscribeServiceDigitalAuto,
} from '../../state';
import { ExploreOtherServicesBoxComponentDigitalAuto } from '../../components';
import { shortUrls } from '../../../../app-urls';

@Component({
  selector: 'app-order-list.da.component',
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    DatePipe,
    ExploreOtherServicesBoxComponentDigitalAuto,
  ],
  templateUrl: './orders.da.component.html',
  styleUrl: './orders.da.component.scss',
})
export class OrdersPageDigitalAuto {
  orders$ = subscribeAndReturnObservable(
    observeFetchSuccess(
      this.store.select((state) => state.digitalAuto.orders)
    ).pipe(
      startWith(
        this.activatedRoute.data.pipe<IOrderListDigitalAuto>(
          map(({ orders }) => orders)
        )
      )
    )
  ) as Observable<IOrderListDigitalAuto>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router
  ) {}
  async unsubscribeService(order: IOrderDigitalAuto) {
    this.store.dispatch(new UnsubscribeServiceDigitalAuto(order));

    await firstValueFrom(
      observeFetchSuccess(
        this.store.select((state) => state.digitalAuto.unsubscribe)
      )
    );
    this.store.dispatch(new LoadOrderListDigitalAuto());
  }
  goToServiceCataloguePage() {
    this.router.navigate([`../${shortUrls.serviceCatalogue}`], {
      relativeTo: this.activatedRoute,
    });
  }
}
