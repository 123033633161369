import { NgClass, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ButtonComponent } from '../../../../components';
import { IVehicleSubscription } from '../../models';

// Construct an array for these keys.
const dynamicKeys = ['quantity', 'severity'] as const;

// Use typeof to derive type from the constant
type IDynamicStatusAttributes = (typeof dynamicKeys)[number];

interface IServiceStatusBaseDigitalAuto {
  type: IDynamicStatusAttributes;
  title: string;
  value: string;
}

@Component({
  selector: 'app-service-status-box-component',
  standalone: true,
  imports: [
    ButtonComponent,
    NgClass,
    FlexLayoutModule,
    TitleCasePipe,
    NgTemplateOutlet,
  ],
  templateUrl: './service-status-box.da.component.html',
  styleUrl: './service-status-box.da.component.scss',
})
export class ServiceStatusBoxComponentDigitalAuto implements OnInit {
  @Input() subscription: IVehicleSubscription | undefined;
  @Output() viewServiceDetailClicked = new EventEmitter<boolean>();

  statusAttributes: Array<IServiceStatusBaseDigitalAuto> = [];
  ngOnInit(): void {
    this.statusAttributes = dynamicKeys.map((key) => ({
      type: key,
      title: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize first letter
      value: this.subscription?.serviceData[key] ?? '', // Set value or empty string if not present
    }));
  }
}
