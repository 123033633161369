import { updateObject } from '../../../shared/util';

export const resolveStateUpdate = <T, U>(
  state: T,
  path: string,
  data: U,
  actionMatched: boolean = true
) => {
  return { matched: actionMatched, newState: updateObject(state, path, data) };
};
