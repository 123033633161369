import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { shortUrls } from 'src/app/app-urls';
import { AppState } from 'src/app/shared/data/state/app.state.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { observeFetchSuccess } from '../../../../shared/util';
import { IServiceCatalogueItemDigitalAuto } from '../../models/service.da.model';
@Component({
  selector: 'app-service-catalogue',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './service-catalogue.da.component.html',
  styleUrl: './service-catalogue.da.component.scss',
})
export class ServiceCatalogueComponentDigitalAuto {
  serviceDetailsURL = `../${shortUrls.serviceDetails}/`;
  brokenImage: boolean = false;
  imageUrl: string = '';
  filteredItems: IServiceCatalogueItemDigitalAuto[] = [];
  allItems: IServiceCatalogueItemDigitalAuto[] = [];
  selectedLanguage$: Observable<string> = this.store$.select(
    (state) => state.interface.language
  );

  service$ = observeFetchSuccess(
    this.store$.select((state) => state.digitalAuto.services)
  );

  searchControl = new FormControl();
  constructor(
    private store$: Store<AppState>,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.service$.subscribe((products) => {
      this.allItems = this.sortOnTitle(products);
      this.filteredItems = this.allItems.map((item) => ({ ...item }));
      this.searchControl.patchValue('');
    });
  }

  sortOnTitle(
    services: IServiceCatalogueItemDigitalAuto[]
  ): IServiceCatalogueItemDigitalAuto[] {
    return sortBy(services, (service) => service?.title) || [];
  }

  searchService(event: any) {
    const value = event.target.value;
    const data: IServiceCatalogueItemDigitalAuto[] = Object.assign(
      this.allItems
    );

    if (value) {
      this.filteredItems = data.filter(
        (item) => item.title.toLowerCase().search(value.toLowerCase()) > -1
      );
    } else {
      this.filteredItems = data;
    }
  }

  updateUrl(validateImage: { type: string }) {
    if (validateImage.type === 'error') {
      this.brokenImage = true;
    }
  }

  servicesTrackByFn(index: number, item: IServiceCatalogueItemDigitalAuto) {
    return item.title;
  }
}
