import { CommonModule, NgClass, NgFor, NgForOf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {
  ImageSliderUtility,
  accessNestedValueSafely,
} from '../../../../../shared/util';
import { isValueChanged } from '../../../../../shared/util/simple-changes.util';
import { IServiceDetailsDigitalAuto } from '../../../models/service.da.model';
import { RatingComponent } from '../../../../../components';

@Component({
  selector: 'app-product-details',
  standalone: true,
  imports: [FlexLayoutModule, NgFor, NgForOf, NgClass, RatingComponent],
  templateUrl: './product-details.da.component.html',
  styleUrl: './product-details.da.component.scss',
})
export class ProductDetailsComponentDigitalAuto implements OnChanges {
  @Input() serviceDetails: IServiceDetailsDigitalAuto | undefined | null =
    undefined;
  imageSlider: ImageSliderUtility | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['serviceDetails'] &&
      isValueChanged(changes['serviceDetails'])
    ) {
      const totalImages = this.serviceDetails?.mediaGallery?.images.length || 0;
      this.imageSlider = new ImageSliderUtility(totalImages);
    }
  }
  getCurrentImageUrl() {
    return accessNestedValueSafely(
      this.serviceDetails?.mediaGallery?.images,
      [this.imageSlider?.getCurrentIndex()?.toString()!, 'imageUrl'],
      ''
    );
  }
}
