<div
  fxLayout="row"
  fxLayout.md="column"
  fxFlexFill
>
  <div
    class="padding-container"
    fxFlex="50"
  >
    <div>
      <img
        class="full-image"
        [src]="getCurrentImageUrl()"
        alt="Lorem ipsum dolor sit amet"
      />

      <div
        class="thumbnail-container"
        fxLayout="row"
      >
        <ng-template
          ngFor
          let-image
          [ngForOf]="serviceDetails?.mediaGallery?.images"
          let-i="index"
        >
          <img
            class="thumbnail-image"
            [src]="image.thumbnailUrl"
            alt="Lorem ipsum dolor sit amet"
            [ngClass]="{
              '-selected': i === imageSlider?.getCurrentIndex()
            }"
          />
        </ng-template>
      </div>
      @if (imageSlider) {
        <div
          class="action-container"
          fxLayout="row"
        >
          <div
            class="index-info"
            fxFlex="50"
          >
            {{ (imageSlider.getCurrentIndex() || 0) + 1 }} of
            {{ serviceDetails?.mediaGallery?.images?.length || 0 }}
          </div>
          <div
            class="button-action"
            fxFlex="50"
          >
            <button
              type="button"
              class="a-button a-button--tertiary -without-label"
              aria-label="accessibility label"
              (click)="imageSlider.move('left')"
            >
              <i
                class="a-icon a-button__icon ui-ic-left"
                title="left"
              ></i>
            </button>
            <button
              type="button"
              class="a-button a-button--tertiary -without-label"
              aria-label="accessibility label"
              (click)="imageSlider.move('right')"
            >
              <i
                class="a-icon a-button__icon ui-ic-right"
                title="right"
              ></i>
            </button>
          </div>
        </div>
      }
    </div>
  </div>
  <div
    class="padding-container"
    fxFlex="50"
  >
    <h2>{{ serviceDetails?.title }}</h2>

    <div>
      <app-rating
        [rating]="serviceDetails?.rating?.aggregateRating!"
        [numberOfRatings]="serviceDetails?.rating?.numberOfRating!"
      ></app-rating>
    </div>

    <p class="service-description">
      {{ serviceDetails?.serviceDetails?.description }}
    </p>

    <h5>Benefits and Features</h5>
    <ul
      class="a-list a-list--dot"
      *ngFor="let item of serviceDetails?.serviceDetails?.benefits"
    >
      <li>
        {{ item }}
      </li>
    </ul>

    <h5>Specifications</h5>
    <ul
      class="specification-ul"
      *ngFor="let item of serviceDetails?.serviceDetails?.specifications"
    >
      <hr class="a-divider" />
      <li>
        <div
          fxLayout="row"
          fxLayout.md="column"
          fxFlexFill
        >
          <div fxFlex="10">
            <i
              class="a-icon boschicon-bosch-ic-{{ item.iconId }}"
              title="{{ item.iconId }}"
            >
            </i>
          </div>
          <div
            fxFlex="30"
            class="boldify"
          >
            {{ item.title }}
          </div>
          <div fxFlex="60">{{ item.description }}</div>
        </div>
      </li>
    </ul>
  </div>
</div>
