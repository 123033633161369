<div class="container">
  <h3 class="a-text">Events history</h3>
  <div
    fxLayout="row"
    fxLayoutGap="20px"
    class="event-history-list"
  >
    @if ((tableData$ | async)?.data?.length) {
      <table
        class="m-table"
        aria-label="Event history"
      >
        <thead>
          @for (mapping of (tableData$ | async)?.headers; track $index) {
            <th>{{ mapping.title }}</th>
          }
        </thead>
        <tbody>
          @for (entry of (tableData$ | async)?.data; track $index) {
            <tr>
              @for (mapping of (tableData$ | async)?.headers; track $index) {
                <td>
                  @if (mapping.key === 'locationLabel') {
                    <div class="a-link">
                      <a
                        aria-label="Open location on google map"
                        [href]="entry['locationUrl']"
                        target="_blank"
                      >
                        <span> {{ entry[mapping.key] }}</span>
                      </a>
                    </div>
                  } @else {
                    {{ entry[mapping.key] }}
                  }
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
    } @else {
      <div class="empty-list">No events triggered yet.</div>
    }
  </div>
</div>
