<div class="container">
  <h3 class="a-text">My orders</h3>
  <div
    fxLayout="row"
    fxLayoutGap="20px"
    class="order-list"
  >
    <table
      class="m-table"
      aria-label="Highlights"
    >
      <thead>
        <tr>
          <th id="serviceName">Service name</th>
          <th id="orderNumber">Order number</th>
          <th id="dateOfPurchase">Date of purchase</th>
          <th id="vin">VIN</th>
          <th id="status">Status</th>
          <th id="action">Action</th>
        </tr>
      </thead>
      <tbody>
        @if ((orders$ | async)?.length) {
          @for (order of orders$ | async; track $index) {
            <ng-container>
              <tr>
                <td>{{ order.service.title }}</td>
                <td>{{ order.subscriptionId }}</td>
                <td>{{ order.subscriptionDate | date: 'dd MMMM yyyy' }}</td>
                <td>{{ order.vehicle.vin }}</td>
                <td>Active</td>
                <td>
                  <span
                    class="unsubscribe-button"
                    (click)="unsubscribeService(order)"
                  >
                    Unsubscribe
                  </span>
                </td>
              </tr>
            </ng-container>
          }
        } @else {
          <div class="empty-list">You have no subscriptions yet.</div>
        }
      </tbody>
    </table>
  </div>

  <div class="explore-service-box-container">
    <app-explore-other-services-box
      (exploreButtonClick)="goToServiceCataloguePage()"
    >
    </app-explore-other-services-box>
  </div>
</div>
