import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SystemBannerComponent } from 'src/app/components';
import { IBannerType } from 'src/app/components/system-banner/system-banner.model';

@Component({
  selector: 'app-error-message',
  standalone: true,
  imports: [SystemBannerComponent],
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
  @Input() errorMessage: string | undefined;
  @Output() closed = new EventEmitter<void>();

  bannerType = IBannerType;

  handleClose() {
    this.closed.emit();
  }
}
