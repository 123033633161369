<div class="frontend-kit-example_tabbed-content">
  <div class="a-tab-navigation__wrapper">
    <div class="a-tab-navigation__gradients"></div>
    <ol
      class="a-tab-navigation"
      role="tablist"
    >
      <ng-template
        ngFor
        let-item
        [ngForOf]="tabItems"
        let-i="index"
      >
        <li
          class="a-tab-navigation__item"
          role="none"
        >
          <button
            type="button"
            class="a-tab-navigation__tab"
            tabindex="0"
            role="tab"
            aria-controls="tab-1"
            id="item"
            (click)="onTabClick(i)"
          >
            <span class="a-tab-navigation__tab-content">
              <span class="a-tab-navigation__label">{{ item }}</span>
            </span>
          </button>
        </li>
      </ng-template>
    </ol>
  </div>
</div>
