import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  ContainerDialogComponent,
  CustomSelectComponent,
  ICustomSelectOptionList,
} from '../../../../components';
import {
  IServiceCatalogueItemDigitalAuto,
  IServiceDetailsDigitalAuto,
  IServicePlanPricingDigitalAuto,
} from '../../models';
import { AppState } from '../../../../shared/data/state/app.state.model';
import { Store } from '@ngrx/store';
import { observeFetchSuccess } from '../../../../shared/util';
import { first, firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-choose-vehicle-dialog',
  standalone: true,
  imports: [
    NgIf,
    ContainerDialogComponent,
    TranslateModule,
    CustomSelectComponent,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  templateUrl: './choose-vehicle-dialog.da.component.html',
  styleUrl: './choose-vehicle-dialog.da.component.scss',
})
export class ChooseVehicleDialogComponentDigitalAuto {
  @Input() selectedPlan: IServicePlanPricingDigitalAuto | null =
    {} as IServicePlanPricingDigitalAuto;
  @Input() selectedService: IServiceDetailsDigitalAuto | undefined | null =
    undefined;
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<string>();
  vehicles$ = observeFetchSuccess(
    this.store.select((state) => state.digitalAuto.vehicles)
  ).pipe(
    first(),
    map(
      (data) =>
        data.map((d) => ({
          label: d.name,
          value: d.vin,
        })) as ICustomSelectOptionList
    )
  );

  vehicleControl = new FormControl('', [Validators.required]);

  constructor(private store: Store<AppState>) {
    (async () => {
      this.vehicleControl.patchValue(
        (await firstValueFrom(this.vehicles$))[0].value
      );
    })();
  }
}
