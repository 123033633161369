import {
  IGlobalStoreDigitalAuto,
  IOrderListDigitalAuto,
  IServiceDetailsDigitalAuto,
  ISubscriptionFlowDigitalAuto,
  IVehicleDigitalAuto,
  IVehicleSubscription,
} from '../models';

export const defaultSubscriptionFlowData: ISubscriptionFlowDigitalAuto = {
  serviceId: '',
  vehicleId: '',
  planId: '',
  orderPlacement: {
    data: {} as IVehicleSubscription,
    progress: 'notDispatchedYet',
  },
};
export const initialStateDigitalAuto: IGlobalStoreDigitalAuto = {
  services: {
    progress: 'notDispatchedYet',
    data: [],
  },

  serviceDetails: {
    progress: 'notDispatchedYet',
    data: {} as IServiceDetailsDigitalAuto,
  },

  vehicles: {
    progress: 'notDispatchedYet',
    data: [] as IVehicleDigitalAuto[],
  },
  subscriptionFlow: defaultSubscriptionFlowData,
  userInfo: {
    progress: 'notDispatchedYet',
    data: {
      sub: '',
      name: '',
      email: '',
      deliveryInformation: {
        email: '',
        phoneNumber: '',
        deliveryAddress: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          zipCode: '',
        },
      },
      paymentInformation: {
        creditCardNumber: '',
        cardExpiry: new Date(),
        cvv: '',
        billingAddress: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          zipCode: '',
        },
      },
    },
  },
  vehicleSubscriptions: {},
  orders: {
    progress: 'notDispatchedYet',
    data: [] as IOrderListDigitalAuto,
  },
  unsubscribe: {
    progress: 'notDispatchedYet',
    data: {
      timestamp: new Date(),
      subscription: {} as IVehicleSubscription,
    },
  },
};
