<div class="container">
  <div
    class="order-container"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    @if (order) {
      <div
        class="order-details"
        fxLayout="column"
        fxLayoutGap="10px"
      >
        <div
          class="delivery"
          fxLayout="column"
        >
          <h5 class="a-text header">
            {{ 'digitalAuto.pages.orderSummary.title' | translate }}
          </h5>
          <div
            class="service"
            fxLayout="row"
            fxLayoutGap="10px"
          >
            <img
              class="service-image"
              [src]="order.service.mediaGallery.images[0].thumbnailUrl"
              [alt]="order.service.title"
            />

            <div
              class="service-details"
              fxLayout="column"
              fxLayoutGap="16px"
            >
              <div>
                <b>{{ order.service.title }}</b>
              </div>
              <div>
                <div>{{ order.plan.title }}</div>
                <div>
                  {{ 'digitalAuto.pages.orderSummary.forVin' | translate }}
                  <b>{{ order.vehicle.vin }}</b>
                </div>
              </div>
            </div>
          </div>
          <hr class="a-divider" />
          <div fxLayout="column">
            <b>
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div class="total">
                  {{ 'digitalAuto.pages.orderSummary.total' | translate }}
                </div>
                <div
                  class="price"
                  fxFlexAlign="end"
                >
                  {{ order.plan.price.currency }}{{ order.plan.price.value }}
                </div>
              </div>
            </b>
            <div class="plan-renews-on-13-february-2024">
              {{
                'digitalAuto.pages.orderSummary.planRenewsOn'
                  | translate
                    : {
                        planRenewalDate:
                          order.plan.planRenewalDate | date: 'dd MMMM yyyy'
                      }
              }}
            </div>
          </div>
        </div>

        <div
          class="delivery"
          fxLayout="column"
        >
          <h5 class="a-text header">
            {{
              'digitalAuto.pages.orderSummary.deliveryInformation' | translate
            }}
          </h5>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div>
              <div>{{ order.user.name }}</div>
              <div>
                {{ order.user.deliveryInformation.deliveryAddress.address1 }}
              </div>
              <div>
                @if (order.user.deliveryInformation.deliveryAddress.address2) {
                  <span>
                    {{
                      order.user.deliveryInformation.deliveryAddress.address2
                    }},
                  </span>
                }
                @if (order.user.deliveryInformation.deliveryAddress.city) {
                  <span>
                    {{ order.user.deliveryInformation.deliveryAddress.city }},
                  </span>
                }
                @if (order.user.deliveryInformation.deliveryAddress.state) {
                  <span>
                    {{ order.user.deliveryInformation.deliveryAddress.state }},
                  </span>
                }
                @if (order.user.deliveryInformation.deliveryAddress.zipCode) {
                  <span>
                    {{ order.user.deliveryInformation.deliveryAddress.zipCode }}
                  </span>
                }
                @if (order.user.deliveryInformation.deliveryAddress.country) {
                  <span>
                    {{
                      order.user.deliveryInformation.deliveryAddress.country
                    }},
                  </span>
                }
              </div>
            </div>
            <button
              type="button"
              class="a-button a-button--tertiary -without-icon"
            >
              <span class="a-button__label">Edit</span>
            </button>
          </div>
          <hr class="a-divider" />
          <div
            class="group-22"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div>
              <div>{{ order.user.deliveryInformation.email }}</div>
              <div>{{ order.user.deliveryInformation.phoneNumber }}</div>
            </div>
            <button
              type="button"
              class="a-button a-button--tertiary -without-icon"
            >
              <span class="a-button__label">Edit</span>
            </button>
          </div>
        </div>

        <div
          class="delivery"
          fxLayout="column"
        >
          <h5 class="a-text">Payment method</h5>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div
              fxLayout="row"
              fxLayoutGap="20px"
              fxLayoutAlign="start center"
            >
              <img
                [src]="visaSVGImageUrl"
                alt="visa image"
              />
              <span class="card-number">**** 9501</span>
            </div>
            <button
              type="button"
              class="a-button a-button--tertiary -without-icon"
            >
              <span class="a-button__label">Edit</span>
            </button>
          </div>
          <hr class="a-divider" />
          <div>
            <b>
              <div class="a-text">Billing address</div>
            </b>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div class="same-as-delivery-address">
                Same as Delivery Address
              </div>
              <button
                type="button"
                class="a-button a-button--tertiary -without-icon"
              >
                <span class="a-button__label">Edit</span>
              </button>
            </div>
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
        >
          <button
            type="button"
            class="a-button a-button--primary -without-icon"
            fxFlex
            (click)="placeOrder()"
          >
            <span class="a-button__label">Place order</span>
          </button>
          <button
            type="button"
            class="a-button a-button--secondary -without-icon"
            fxFlex
          >
            <span class="a-button__label">Cancel</span>
          </button>
        </div>
      </div>
    }
  </div>
</div>

@if (showOrderErrorMessage$ | async) {
  <app-error-message
    [errorMessage]="placeOrderErrorMessage"
    (closed)="this.showOrderErrorMessage$.next(false)"
  >
  </app-error-message>
}

@if (showOrderSuccessDialog$ | async) {
  <app-order-success-dialog
    [subscriptionId]="subscriptionId ?? ''"
    (save)="closeDialog()"
    (close)="closeDialog()"
  >
  </app-order-success-dialog>
}
