import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { AppState } from '../../../shared/data/state/app.state.model';
import { observeFetchSuccess } from '../../../shared/util';
import { IServiceDetailsDigitalAuto } from '../models';
import { LoadServiceDetailsDigitalAuto } from '../state';

export const serviceDetailPageDataResolverDigitalAuto: ResolveFn<
  IServiceDetailsDigitalAuto
> = async (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
  _: RouterStateSnapshot
) => {
  const store = inject(Store) as Store<AppState>;
  const serviceId = activatedRouteSnapshot.paramMap.get('serviceId') as string;
  store.dispatch(new LoadServiceDetailsDigitalAuto(serviceId));

  return await firstValueFrom(
    observeFetchSuccess(
      store.select((state) => state.digitalAuto.serviceDetails)
    )
  );
};
