<app-container-dialog
  header="{{ 'digitalAuto.dialogs.chooseVehicle.title' | translate }}"
  body="{{
    'digitalAuto.dialogs.chooseVehicle.description'
      | translate
        : { planType: selectedPlan?.title, serviceName: selectedService?.title }
  }}"
  [hideCancelButton]="false"
  [confirmButtonDisabled]="vehicleControl.invalid"
  [theme]="'neutral'"
  [confirmButtonText]="
    'digitalAuto.dialogs.chooseVehicle.buttons.confirm' | translate
  "
  (confirmed)="save.emit(vehicleControl['value'] || undefined)"
  (cancelled)="close.emit()"
>
  <div
    elTestId="hI5ad93kDShiDg0I7M51_"
    class="m-form-field"
  >
    <div>
      {{
        'digitalAuto.dialogs.chooseVehicle.fields.selectVehicle.label'
          | translate
      }}
    </div>
    <div class="a-dropdown">
      <select
        [formControl]="vehicleControl"
        [attr.aria-label]="
          'digitalAuto.dialogs.chooseVehicle.fields.selectVehicle.label'
            | translate
        "
      >
        @for (item of vehicles$ | async; track $index) {
          <option [value]="item.value">
            {{ item.label }}
          </option>
        }
      </select>
    </div>
  </div>
</app-container-dialog>
