import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { map, tap } from 'rxjs';
import { shortUrls } from '../../../../app-urls';
import { ButtonComponent } from '../../../../components';
import { ToastMessageService } from '../../../../components/toast/toast-message.service';
import { subscribeAndReturnObservable } from '../../../../shared/util';
import {
  ExploreOtherServicesBoxComponentDigitalAuto,
  ServiceStatusBoxComponentDigitalAuto,
} from '../../components';
import {
  IVehicleDigitalAuto,
  IVehicleSubscription,
  IVehicleSubscriptionList,
} from '../../models';
import { IServiceListingForVehicleRouteData } from '../../models/service-listing-for-vehicle.da.model';

@Component({
  selector: 'app-services-listing-for-vehicle-page',
  standalone: true,
  imports: [
    ServiceStatusBoxComponentDigitalAuto,
    FlexLayoutModule,
    ButtonComponent,
    ExploreOtherServicesBoxComponentDigitalAuto,
  ],
  templateUrl: './services-listing-for-vehicle-page.da.component.html',
  styleUrl: './services-listing-for-vehicle-page.da.component.scss',
})
export class ServicesListingForVehiclePageComponent implements OnInit {
  vehicle: IVehicleDigitalAuto | undefined;
  subscriptions: IVehicleSubscriptionList | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastMessageService: ToastMessageService
  ) {
    subscribeAndReturnObservable<IServiceListingForVehicleRouteData>(
      this.activatedRoute.data.pipe(
        map(({ pageData }) => ({
          vehicle: pageData.vehicle,
          subscriptions: pageData.subscriptions,
          serviceStatus: pageData.serviceStatus,
        })),
        tap(({ vehicle, subscriptions, serviceStatus }) => {
          this.subscriptions = subscriptions;
          this.vehicle = vehicle;
        })
      )
    );
  }

  ngOnInit(): void {
    const subscriptionsWithWarning = (this.subscriptions ?? [])
      .filter((s) => s.serviceData.severity === 'warning')
      .map((s) => ({
        action: s.serviceData.action,
        mesage: s.serviceData.diagnosisMessage,
      }));

    for (const subscription of subscriptionsWithWarning) {
      this.toastMessageService.showError(subscription.mesage);
      this.toastMessageService.showInfo(subscription.action);
    }
  }

  goToServiceCataloguePage() {
    this.router.navigate([`../../../${shortUrls.serviceCatalogue}`], {
      relativeTo: this.activatedRoute,
    });
  }

  onServiceDetailClick(subscription: IVehicleSubscription) {
    this.router.navigate(
      [
        `../../../${shortUrls.serviceDetails}/${subscription.service.serviceId}`,
      ],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }
}
