<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="20px"
>
  <img
    class="full-image"
    [src]="getCurrentImageUrl()"
    alt="highlighted-image"
  />

  <div
    class="action-container"
    fxLayout="row"
  >
    <div
      class="button-action"
      fxFlex="50"
    >
      <div class="a-page-indicator">
        <div class="a-page-indicator__container">
          <ng-template
            ngFor
            let-image
            [ngForOf]="this.serviceDetails?.mediaHighlights?.images"
            let-index="index"
          >
            <button
              type="button"
              class="a-page-indicator__indicator"
              [ngClass]="{
                '-selected': index === imageSlider?.getCurrentIndex()
              }"
            ></button>
          </ng-template>
        </div>
      </div>
    </div>
    <div
      class="button-action"
      fxFlex="50"
    >
      @if (imageSlider) {
        <button
          type="button"
          class="a-button a-button--tertiary -without-label"
          aria-label="accessibility label"
          (click)="imageSlider.move('left')"
        >
          <i
            class="a-icon a-button__icon ui-ic-left"
            title="left"
          ></i>
        </button>
        <button
          type="button"
          class="a-button a-button--tertiary -without-label"
          aria-label="accessibility label"
          (click)="imageSlider.move('right')"
        >
          <i
            class="a-icon a-button__icon ui-ic-right"
            title="right"
          ></i>
        </button>
      }
    </div>
  </div>

  <div
    fxLayout="column"
    fxLayoutGap="20px"
  >
    @for (item of highligts; track $index) {
      <div>
        <h5 class="a-text">{{ item.title }}</h5>
        <div class="a-text">{{ item.description }}</div>
      </div>
    }
  </div>
</div>
