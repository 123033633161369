<div
  class="status-box"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="10px"
>
  <div
    fxLayout="row"
    fxLayoutGap="10px"
  >
    <div class="service-icon">
      <em
        class="a-icon boschicon-bosch-ic-windshield-spray"
        title="windshield-spray"
      ></em>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
    >
      <div>
        {{ subscription?.serviceData?.diagnosisTitle }}
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
      >
        @for (status of statusAttributes; track $index) {
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="status-type--{{ status.type }}"
          >
            @if (status.type === 'severity') {
              <div
                class="{{ ' severity--' + status.value }}"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="5px"
              >
                <ng-container
                  *ngTemplateOutlet="
                    severityIconTemplate;
                    context: { $implicit: status.value }
                  "
                ></ng-container>
                <span>
                  @switch (status.value) {
                    @case ('warning') {
                      Low
                    }
                    @default {
                      Normal
                    }
                  }
                </span>
              </div>
            } @else {
              <span> {{ status.value | titlecase }}</span>
            }
          </div>
        }
      </div>
      @if (
        subscription?.serviceData?.severity === 'warning' &&
        subscription?.serviceData?.action
      ) {
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px"
        >
          <ng-container
            *ngTemplateOutlet="
              severityIconTemplate;
              context: { $implicit: 'normal' }
            "
          ></ng-container>
          {{ subscription?.serviceData?.action }}
        </div>
      }
    </div>
  </div>
  <div>
    <app-button
      buttonLabel="Service Details"
      icon="ui-ic-right-small"
      iconPlacement="right"
      theme="tertiary"
      (clicked)="viewServiceDetailClicked.emit(true)"
    >
    </app-button>
  </div>
</div>

<ng-template
  #severityIconTemplate
  let-value
>
  @if (value === 'warning') {
    <em
      class="a-icon ui-ic-alert-warning"
      title="info-i-frame"
    ></em>
  } @else if (value === 'normal') {
    <em
      class="a-icon boschicon-bosch-ic-info-i-frame"
      title="info-i-frame"
    ></em>
  }
</ng-template>
