import { ServiceCatalogueEffectsDigitalAuto } from './service-catalogue.da.effect';
import { SubscriptionEffectsDigitalAuto } from './subscription.da.effect';
import { UserEffectsDigitalAuto } from './user.da.effect';
import { VehicleEffectsDigitalAuto } from './vehicle.da.effect';

export const allEffectsDigitalAuto = [
  ServiceCatalogueEffectsDigitalAuto,
  VehicleEffectsDigitalAuto,
  UserEffectsDigitalAuto,
  SubscriptionEffectsDigitalAuto,
];

export * from './service-catalogue.da.effect';
export * from './vehicle.da.effect';
export * from './user.da.effect';
export * from './subscription.da.effect';
