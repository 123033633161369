import { Action } from '@ngrx/store';
import {
  IOrderDigitalAuto,
  IPlaceOrderApiData,
  IProgressDigitalAuto,
  ISetVehicleSubscriptionsActionPayloadDigitalAuto,
  IUnsubscribeStateDataDigitalAuto,
  IVehicleSubscription,
  IVehicleSubscriptionsProgressActionPayloadDigitalAuto,
} from '../../models';
// For setting service ID in Subscription Flow
export const SET_SERVICEID_SUBSCRIPTION_FLOW_DIGITAL_AUTO =
  '[Digital Auto] Set ServiceID in Subscription Flow';

export class SetServiceIdForSubscriptionFlowDigitalAuto implements Action {
  readonly type = SET_SERVICEID_SUBSCRIPTION_FLOW_DIGITAL_AUTO;
  constructor(public payload: string) {}
}

// For setting vehicle ID in Subscription Flow
export const SET_VEHICLEID_SUBSCRIPTION_FLOW_DIGITAL_AUTO =
  '[Digital Auto] Set VehicleID in Subscription Flow';

export class SetVehicleIdForSubscriptionFlowDigitalAuto implements Action {
  readonly type = SET_VEHICLEID_SUBSCRIPTION_FLOW_DIGITAL_AUTO;
  constructor(public payload: string) {}
}

export const SET_PLANID_SUBSCRIPTION_FLOW_DIGITAL_AUTO =
  '[Digital Auto] Set PlanID in Subscription Flow';

export class SetPlanIdForSubscriptionFlowDigitalAuto implements Action {
  readonly type = SET_PLANID_SUBSCRIPTION_FLOW_DIGITAL_AUTO;
  constructor(public payload: string) {}
}

export const LOAD_VEHICLE_SUBSCRIPTION_DIGITAL_AUTO: string =
  '[User Subscription Digital Auto] Load Vehicle Subscription Digital Auto';

export class LoadVehicleSubscriptionDigitalAuto implements Action {
  readonly type = LOAD_VEHICLE_SUBSCRIPTION_DIGITAL_AUTO;
  constructor(public payload: string) {}
}

export const SET_VEHICLE_SUBSCRIPTION_PROGRESS_DIGITAL_AUTO: string =
  '[User Subscription Digital Auto] Set Vehicle Subscription Digital Auto Progress';

export class SetVehicleSubscriptionProgressDigitalAuto implements Action {
  readonly type = SET_VEHICLE_SUBSCRIPTION_PROGRESS_DIGITAL_AUTO;
  constructor(
    public payload: IVehicleSubscriptionsProgressActionPayloadDigitalAuto
  ) {}
}

export const SET_VEHICLE_SUBSCRIPTION_DIGITAL_AUTO: string =
  '[User Subscription Digital Auto] Set Vehicle Subscription Digital Auto';

export class SetVehicleSubscriptionDigitalAuto implements Action {
  readonly type = SET_VEHICLE_SUBSCRIPTION_DIGITAL_AUTO;
  constructor(
    public payload: ISetVehicleSubscriptionsActionPayloadDigitalAuto
  ) {}
}

export const PLACE_ORDER_DIGITAL_AUTO: string = '[Digital Auto] Place Order';

export class PlaceOrderDigitalAuto implements Action {
  readonly type = PLACE_ORDER_DIGITAL_AUTO;
  constructor(public payload: IPlaceOrderApiData) {}
}

export const SET_PLACE_ORDER_PROGRESS_DIGITAL_AUTO: string =
  '[Digital Auto] Set Place Order Loading State Digital Auto';

export class SetPlaceOrderProgressDigitalAuto implements Action {
  readonly type = SET_PLACE_ORDER_PROGRESS_DIGITAL_AUTO;
  constructor(public payload: IProgressDigitalAuto) {}
}

export const SET_PLACE_ORDER_API_RESPONSE_DIGITAL_AUTO: string =
  '[Digital Auto] Set Place Order Api Response';

export class SetPlaceOrderApiResponseDigitalAuto implements Action {
  readonly type = SET_PLACE_ORDER_API_RESPONSE_DIGITAL_AUTO;
  constructor(public payload: IVehicleSubscription) {}
}

export const RESET_SUBSCRIPTION_FLOW_DIGITAL_AUTO: string =
  '[Digital Auto] Reset subscription flow';
export class ResetSubscriptionFlowDigitalAuto implements Action {
  readonly type = RESET_SUBSCRIPTION_FLOW_DIGITAL_AUTO;
  constructor(public payload: void) {}
}

// Unsubscribe action types
export const UNSUBSCRIBE_SERVICE_DIGITAL_AUTO: string =
  '[Digital Auto] Unsubscribe Service';
export class UnsubscribeServiceDigitalAuto implements Action {
  readonly type = UNSUBSCRIBE_SERVICE_DIGITAL_AUTO;
  constructor(public payload: IOrderDigitalAuto) {}
}

// Unsubscribe action classes
export const SET_UNSUBSCRIBE_PROGRESS_DIGITAL_AUTO: string =
  '[Digital Auto] Set Unsubscribe Loading State Digital Auto';
export class SetUnsubscribeProgressDigitalAuto implements Action {
  readonly type = SET_UNSUBSCRIBE_PROGRESS_DIGITAL_AUTO;
  constructor(public payload: IProgressDigitalAuto) {}
}

export const SAVE_UNSUBSCRIBED_SERVICE_DIGITAL_AUTO: string =
  '[Digital Auto] Save Unsubscribed Service Digital Auto';
export class SaveUnsubscribedServiceDigitalAuto implements Action {
  readonly type = SET_UNSUBSCRIBE_PROGRESS_DIGITAL_AUTO;
  constructor(public payload: IUnsubscribeStateDataDigitalAuto) {}
}

export const RESET_PLACE_ORDER_PROGRESS_DIGITAL_AUTO: string =
  '[Digital Auto] Reset Place Order Progress';

export class ResetPlaceOrderProgressDigitalAuto implements Action {
  readonly type = RESET_PLACE_ORDER_PROGRESS_DIGITAL_AUTO;
  constructor(public payload: void) {}
}

export type ISubscriptionFlowActionsDigitalAuto =
  | SetServiceIdForSubscriptionFlowDigitalAuto
  | SetVehicleIdForSubscriptionFlowDigitalAuto
  | SetPlanIdForSubscriptionFlowDigitalAuto
  | PlaceOrderDigitalAuto
  | SetPlaceOrderProgressDigitalAuto
  | SetPlaceOrderApiResponseDigitalAuto
  | UnsubscribeServiceDigitalAuto
  | SetUnsubscribeProgressDigitalAuto
  | SaveUnsubscribedServiceDigitalAuto
  | LoadVehicleSubscriptionDigitalAuto
  | SetVehicleSubscriptionProgressDigitalAuto
  | SetVehicleSubscriptionDigitalAuto
  | ResetSubscriptionFlowDigitalAuto
  | ResetPlaceOrderProgressDigitalAuto;
