<div class="container">
  <h3 class="a-text">My vehicles</h3>
  <div>
    <app-gray-box>
      <div
        class="list-toolbar"
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <div class="list-toolbar-right">
          <button
            type="button"
            class="a-button a-button--secondary"
            [attr.aria-label]="'Add vehicle'"
          >
            <i
              class="a-icon a-button__icon boschicon-bosch-ic-add"
              title="add vehicle"
            ></i>
            <span class="a-button__label">Add vehicle</span>
          </button>
        </div>
      </div>
    </app-gray-box>
  </div>
  <div class="vehicle-list">
    @if ((vehicles$ | async)?.length) {
      @for (vehicle of vehicles$ | async; track $index) {
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="24px"
          class="vehicle"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="24px"
          >
            <h4>{{ vehicle.name }}</h4>
            <div>VIN: {{ vehicle.vin }}</div>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <img
                class="vehicle-image"
                [src]="vehicle.heroImageUrl"
                [alt]="vehicle.name"
              />
            </div>
          </div>
          <div class="a-link -icon">
            <a
              aria-label="view services"
              href="javascript:void(0)"
              class="a-button a-button--tertiary"
              fxLayout="row"
              fxLayoutAlign="start center"
              (click)="viewServices(vehicle.vin)"
            >
              <span>View Services</span>
              <span>
                <i
                  class="a-icon ui-ic-nosafe-lr-right-small"
                  title="nosafe-lr-right-small"
                ></i>
              </span>
            </a>
          </div>
        </div>
      }
    } @else {
      <div class="empty-list">No vehicles yet</div>
    }
  </div>
</div>
