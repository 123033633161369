import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { AppState } from '../../../shared/data/state/app.state.model';
import { observeFetchSuccess } from '../../../shared/util';
import { IServiceCatalgoueItemListDigitalAuto } from '../models';
import { LoadServiceCatalogueDigitalAuto } from '../state';

export const serviceCataloguePageDataResolverDigitalAuto: ResolveFn<
  IServiceCatalgoueItemListDigitalAuto
> = async (__: ActivatedRouteSnapshot, _: RouterStateSnapshot) => {
  const store = inject(Store) as Store<AppState>;
  store.dispatch(new LoadServiceCatalogueDigitalAuto());

  return await firstValueFrom(
    observeFetchSuccess(store.select((state) => state.digitalAuto.services))
  );
};
