import { CommonModule, NgClass, NgFor } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {
  ImageSliderUtility,
  accessNestedValueSafely,
} from '../../../../../shared/util';
import { isValueChanged } from '../../../../../shared/util/simple-changes.util';
import { IServiceDetailsDigitalAuto } from '../../../models/service.da.model';

@Component({
  selector: 'app-highlights',
  standalone: true,
  imports: [FlexLayoutModule, NgFor, NgClass],
  templateUrl: './highlights.da.component.html',
  styleUrl: './highlights.da.component.scss',
})
export class HighlightsComponentDigitalAuto {
  @Input() serviceDetails: IServiceDetailsDigitalAuto | undefined | null =
    undefined;

  imageSlider: ImageSliderUtility | undefined;
  readonly highligts = [
    {
      title: 'Effortless Convenience',
      description:
        "Enjoy the convenience of our washer fluid replenishment service, ensuring your vehicle's windshield stays clear without the hassle of doing it yourself.",
    },
    {
      title: 'Tailored to Your Schedule',
      description:
        'We understand your busy lifestyle. Our washer fluid replenishment service is designed to fit seamlessly into your schedule, offering a hassle-free solution to keep your vehicle in top condition.',
    },
    {
      title: 'Expertly Administered Fluid',
      description:
        'Trust in the quality of our washer fluid. Our service uses expertly formulated solutions to ensure optimal cleaning performance, preventing streaks and maintaining crystal-clear windshields.',
    },
    {
      title: 'Subscription Options Available',
      description:
        'Simplify your life with subscription plans for regular washer fluid refills. Never worry about running out again, and enjoy continuous clarity on the road.',
    },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['serviceDetails'] &&
      isValueChanged(changes['serviceDetails'])
    ) {
      const totalImages =
        this.serviceDetails?.mediaHighlights?.images.length ?? 0;
      this.imageSlider = new ImageSliderUtility(totalImages);
    }
  }
  getCurrentImageUrl() {
    return accessNestedValueSafely(
      this.serviceDetails?.mediaHighlights?.images,
      [this.imageSlider?.getCurrentIndex()?.toString()!, 'imageUrl'],
      ''
    );
  }
}
