import {
  serviceCatalogueReducerDigitalAuto,
  subscriptionReducerDigitalAuto,
  userReducerDigitalAuto,
  vehicleReducerDigitalAuto,
} from '.';
import { IGlobalStoreDigitalAuto } from '../../models/store.da.model';
import { initialStateDigitalAuto } from '../../resources';
import { IAllActionsDigitalAuto } from '../actions';

const tryReducer = (
  state: IGlobalStoreDigitalAuto,
  action: IAllActionsDigitalAuto,
  reducer: (
    s: IGlobalStoreDigitalAuto,
    a: IAllActionsDigitalAuto
  ) => {
    matched: boolean;
    newState: IGlobalStoreDigitalAuto;
  }
): {
  matched: boolean;
  newState: IGlobalStoreDigitalAuto;
} => {
  const reducedState = reducer(state, action);
  return reducedState.matched
    ? reducedState
    : { matched: false, newState: state };
};

const globalStoreReducerHelper = (
  state: IGlobalStoreDigitalAuto,
  action: IAllActionsDigitalAuto,
  reducersToTry: ((
    s: IGlobalStoreDigitalAuto,
    a: IAllActionsDigitalAuto
  ) => { matched: boolean; newState: IGlobalStoreDigitalAuto })[]
): IGlobalStoreDigitalAuto => {
  let reducedState = state;

  for (const reducer of reducersToTry) {
    const result = tryReducer(reducedState, action, reducer);
    if (result.matched) {
      reducedState = result.newState;
      break;
    }
  }

  return reducedState;
};

export function globalStoreReducerDigitalAuto(
  state: IGlobalStoreDigitalAuto = initialStateDigitalAuto,
  action: IAllActionsDigitalAuto
): IGlobalStoreDigitalAuto {
  // Add all reducers here.
  const reducersToTry = [
    serviceCatalogueReducerDigitalAuto,
    userReducerDigitalAuto,
    vehicleReducerDigitalAuto,
    subscriptionReducerDigitalAuto,
  ];

  return globalStoreReducerHelper(state, action, reducersToTry);
}
