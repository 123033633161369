import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '../../../../components';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@Component({
  selector: 'app-explore-other-services-box',
  standalone: true,
  imports: [ButtonComponent, FlexLayoutModule],
  templateUrl: './explore-other-services-box.da.component.html',
  styleUrl: './explore-other-services-box.da.component.scss',
})
export class ExploreOtherServicesBoxComponentDigitalAuto {
  @Output() exploreButtonClick = new EventEmitter<boolean>();
}
