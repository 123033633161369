import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ContainerDialogComponent } from '../../../../components';

@Component({
  selector: 'app-order-success-dialog',
  standalone: true,
  imports: [
    NgIf,
    ContainerDialogComponent,
    TranslateModule,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  templateUrl: './order-success-dialog.da.component.html',
  styleUrl: './order-success-dialog.da.component.scss',
})
export class OrderSuccessDialogComponentDigitalAuto {
  @Input() subscriptionId: string | null = '';
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();
}
