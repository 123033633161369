<div class="container">
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="40px"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <div>
        <h4 class="a-text">{{ vehicle?.name }}</h4>
        <div>VIN: {{ vehicle?.vin }}</div>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <img
          class="vehicle-image"
          [src]="vehicle?.heroImageUrl"
          [alt]="vehicle?.name"
        />
      </div>
    </div>

    <div>
      <div>
        <h5 class="a-text">My Services</h5>
        @if (subscriptions?.length) {
          <div
            fxLayout="row"
            fxLayoutGap="20px"
          >
            @for (subscription of subscriptions; track $index) {
              <app-service-status-box-component
                [subscription]="subscription"
                (viewServiceDetailClicked)="onServiceDetailClick(subscription)"
              >
              </app-service-status-box-component>
            }
          </div>
        } @else {
          <div class="empty-list">No services yet</div>
        }
      </div>
    </div>

    <app-explore-other-services-box
      (exploreButtonClick)="goToServiceCataloguePage()"
    >
    </app-explore-other-services-box>
  </div>
</div>
