import { defaultSubscriptionFlowData } from '../..';
import {
  IProgressDigitalAuto,
  ISetVehicleSubscriptionsActionPayloadDigitalAuto,
  IVehicleDigitalAuto,
  IVehicleInfoProgressActionPayloadDigitalAuto,
  IVehicleSubscriptionList,
} from '../../models';
import { IGlobalStoreDigitalAuto } from '../../models/store.da.model';
import { resolveStateUpdate } from '../../utils';
import {
  ISubscriptionFlowActionsDigitalAuto,
  LOAD_ORDER_LIST_DIGITAL_AUTO,
  LOAD_VEHICLE_INFO_DIGITAL_AUTO,
  LOAD_VEHICLE_SUBSCRIPTION_DIGITAL_AUTO,
  PLACE_ORDER_DIGITAL_AUTO,
  RESET_SUBSCRIPTION_FLOW_DIGITAL_AUTO,
  SAVE_UNSUBSCRIBED_SERVICE_DIGITAL_AUTO,
  SET_ORDER_LIST_DIGITAL_AUTO,
  SET_ORDER_LIST_PROGRESS_DIGITAL_AUTO,
  SET_PLACE_ORDER_API_RESPONSE_DIGITAL_AUTO,
  SET_PLACE_ORDER_PROGRESS_DIGITAL_AUTO,
  SET_PLANID_SUBSCRIPTION_FLOW_DIGITAL_AUTO,
  SET_SERVICEID_SUBSCRIPTION_FLOW_DIGITAL_AUTO,
  SET_UNSUBSCRIBE_PROGRESS_DIGITAL_AUTO,
  SET_VEHICLEID_SUBSCRIPTION_FLOW_DIGITAL_AUTO,
  SET_VEHICLE_INFO_DIGITAL_AUTO,
  SET_VEHICLE_INFO_PROGRESS_DIGITAL_AUTO,
  SET_VEHICLE_SUBSCRIPTION_DIGITAL_AUTO,
  SET_VEHICLE_SUBSCRIPTION_PROGRESS_DIGITAL_AUTO,
  UNSUBSCRIBE_SERVICE_DIGITAL_AUTO,
  RESET_PLACE_ORDER_PROGRESS_DIGITAL_AUTO,
} from '../actions';

export const subscriptionReducerDigitalAuto = (
  state: IGlobalStoreDigitalAuto,
  action: ISubscriptionFlowActionsDigitalAuto
) => {
  const initializeVehicleDataIfNeeded = (vinNumber: string) => {
    // Check if the vinNumber exists in state.vehicleSubscriptions
    if (vinNumber in state.vehicleSubscriptions) {
      // If it exists, return the current state
      return state;
    } else {
      // If it doesn't exist, create a new reference with the updated vehicleSubscriptions
      const newState: IGlobalStoreDigitalAuto = {
        ...state,
        vehicleSubscriptions: {
          ...state.vehicleSubscriptions,
          [vinNumber]: {
            vehicle: {
              progress: 'notDispatchedYet' as IProgressDigitalAuto,
              data: {} as IVehicleDigitalAuto,
            },
            subscriptions: {
              progress: 'hasBeenDispatched' as IProgressDigitalAuto,
              data: [] as IVehicleSubscriptionList,
            },
          },
        },
      };

      return newState;
    }
  };

  switch (action.type) {
    case SET_SERVICEID_SUBSCRIPTION_FLOW_DIGITAL_AUTO:
      return resolveStateUpdate(
        state,
        'subscriptionFlow.serviceId',
        action.payload
      );

    case SET_VEHICLEID_SUBSCRIPTION_FLOW_DIGITAL_AUTO:
      return resolveStateUpdate(
        state,
        'subscriptionFlow.vehicleId',
        action.payload
      );

    case SET_PLANID_SUBSCRIPTION_FLOW_DIGITAL_AUTO:
      return resolveStateUpdate(
        state,
        'subscriptionFlow.planId',
        action.payload
      );

    case PLACE_ORDER_DIGITAL_AUTO:
      return resolveStateUpdate(
        state,
        'subscriptionFlow.orderPlacement.progress',
        'hasBeenDispatched' as IProgressDigitalAuto
      );
    case SET_PLACE_ORDER_PROGRESS_DIGITAL_AUTO:
      return resolveStateUpdate(
        state,
        'subscriptionFlow.orderPlacement.progress',
        action.payload
      );
    case SET_PLACE_ORDER_API_RESPONSE_DIGITAL_AUTO:
      return resolveStateUpdate(
        state,
        'subscriptionFlow.orderPlacement.data',
        action.payload
      );
    case RESET_SUBSCRIPTION_FLOW_DIGITAL_AUTO:
      return resolveStateUpdate(
        state,
        'subscriptionFlow',
        defaultSubscriptionFlowData
      );

    case LOAD_VEHICLE_INFO_DIGITAL_AUTO: {
      return resolveStateUpdate(
        initializeVehicleDataIfNeeded(action.payload as string),
        `vehicleSubscriptions.${action.payload as string}.vehicle.progress`,
        'hasBeenDispatched'
      );
    }

    case SET_VEHICLE_INFO_PROGRESS_DIGITAL_AUTO: {
      const { vin, progress } =
        action.payload as IVehicleInfoProgressActionPayloadDigitalAuto;
      return resolveStateUpdate(
        initializeVehicleDataIfNeeded(vin),
        `vehicleSubscriptions.${vin}.vehicle.progress`,
        progress
      );
    }

    case SET_VEHICLE_INFO_DIGITAL_AUTO: {
      const { vin } = action.payload as unknown as IVehicleDigitalAuto;
      return resolveStateUpdate(
        initializeVehicleDataIfNeeded(vin),
        `vehicleSubscriptions.${vin}.vehicle.data`,
        action.payload
      );
    }

    case LOAD_VEHICLE_SUBSCRIPTION_DIGITAL_AUTO:
      return resolveStateUpdate(
        initializeVehicleDataIfNeeded(action.payload as string),
        `vehicleSubscriptions.${
          action.payload as string
        }.subscriptions.progress`,
        'hasBeenDispatched'
      );

    case SET_VEHICLE_SUBSCRIPTION_PROGRESS_DIGITAL_AUTO: {
      const { vin, progress } =
        action.payload as IVehicleInfoProgressActionPayloadDigitalAuto;
      return resolveStateUpdate(
        initializeVehicleDataIfNeeded(vin),
        `vehicleSubscriptions.${vin}.subscriptions.progress`,
        progress
      );
    }

    case SET_VEHICLE_SUBSCRIPTION_DIGITAL_AUTO: {
      const { vin, subscriptions } =
        action.payload as unknown as ISetVehicleSubscriptionsActionPayloadDigitalAuto;
      return resolveStateUpdate(
        initializeVehicleDataIfNeeded(vin),
        `vehicleSubscriptions.${vin}.subscriptions.data`,
        subscriptions
      );
    }

    case LOAD_ORDER_LIST_DIGITAL_AUTO:
      return resolveStateUpdate(
        state,
        'orders.progress',
        'hasBeenDispatched' as IProgressDigitalAuto
      );
    case SET_ORDER_LIST_PROGRESS_DIGITAL_AUTO:
      return resolveStateUpdate(state, 'orders.progress', action.payload);
    case SET_ORDER_LIST_DIGITAL_AUTO:
      return resolveStateUpdate(state, 'orders.data', action.payload);

    case UNSUBSCRIBE_SERVICE_DIGITAL_AUTO:
      return resolveStateUpdate(
        state,
        'unsubscribe.progress',
        'hasBeenDispatched'
      );
    case SET_UNSUBSCRIBE_PROGRESS_DIGITAL_AUTO:
      return resolveStateUpdate(state, 'unsubscribe.progress', action.payload);
    case SAVE_UNSUBSCRIBED_SERVICE_DIGITAL_AUTO:
      return resolveStateUpdate(state, 'unsubscribe.data', action.payload);
    case RESET_PLACE_ORDER_PROGRESS_DIGITAL_AUTO:
      return resolveStateUpdate(
        state,
        'subscriptionFlow.orderPlacement.progress',
        null
      );
    default:
      return { newState: state, matched: false };
  }
};
