<app-container-dialog
  header="{{ 'digitalAuto.dialogs.orderSuccess.title' | translate }}"
  body="{{
    'digitalAuto.dialogs.orderSuccess.description'
      | translate: { subscriptionId: subscriptionId }
  }}"
  [hideCancelButton]="true"
  [confirmButtonDisabled]="false"
  [theme]="'success'"
  [confirmButtonText]="
    'digitalAuto.dialogs.orderSuccess.buttons.confirm' | translate
  "
  (confirmed)="save.emit()"
  (cancelled)="close.emit()"
>
  <div class="m-form-field"></div>
</app-container-dialog>
