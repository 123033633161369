import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ResolveFn,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../shared/data/state/app.state.model';
import { inject } from '@angular/core';
import { IOrderSummary } from '../models/order-summary.da.model';
import {
  LoadServiceDetailsDigitalAuto,
  LoadUserDeliveryInfoDigitalAuto,
  SetPlanIdForSubscriptionFlowDigitalAuto,
  SetServiceIdForSubscriptionFlowDigitalAuto,
  SetVehicleIdForSubscriptionFlowDigitalAuto,
} from '../state';
import { observeFetchSuccess } from '../../../shared/util';
import { firstValueFrom } from 'rxjs';
import { add } from 'date-fns';
import { IServicePlanPricingDigitalAuto, IVehicleDigitalAuto } from '../models';

export const orderSummaryPageDataResolverDigitalAuto: ResolveFn<
  IOrderSummary
> = async (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
  _: RouterStateSnapshot
) => {
  const store = inject(Store) as Store<AppState>;
  const serviceId = activatedRouteSnapshot.paramMap.get('serviceId') as string;
  const planId = activatedRouteSnapshot.paramMap.get('planId') as string;
  const vehicleId = activatedRouteSnapshot.paramMap.get('vehicleId') as string;

  // Must load user info even if coming from previous page
  store.dispatch(new LoadUserDeliveryInfoDigitalAuto());
  const user = await firstValueFrom(
    observeFetchSuccess(store.select((state) => state.digitalAuto.userInfo))
  );

  let service = await firstValueFrom(
    store.select((state) => state.digitalAuto.serviceDetails.data)
  );
  if (!service.serviceId) {
    store.dispatch(new LoadServiceDetailsDigitalAuto(serviceId));
    service = await firstValueFrom(
      observeFetchSuccess(
        store.select((state) => state.digitalAuto.serviceDetails)
      )
    );
    store.dispatch(new SetServiceIdForSubscriptionFlowDigitalAuto(serviceId));
    store.dispatch(new SetVehicleIdForSubscriptionFlowDigitalAuto(vehicleId));
    store.dispatch(new SetPlanIdForSubscriptionFlowDigitalAuto(planId));
  }

  const matchedPlan = service.plans.find(
    (p) => p.planId === planId
  ) as IServicePlanPricingDigitalAuto;
  return {
    plan: { ...matchedPlan, planRenewalDate: add(new Date(), { years: 1 }) },
    vehicle: { vin: vehicleId } as IVehicleDigitalAuto,
    service,
    user,
  };
};
