<div class="a-tile plans-container">
  <ng-container *ngFor="let item of serviceDetails?.plans">
    <div class="box">
      <div
        fxLayout="column"
        fxFlexFill
      >
        <div fxFlex="30">
          <div class="app-icon-img">
            <img
              class="alt-image"
              [src]="item?.image"
              alt="No Logo"
            />
          </div>
        </div>
        <div fxFlex="50">
          <div class="body-container">
            <div class="col-title">
              <div
                class="app-title"
                title="{{ item.title }}"
              >
                {{ item.title }}
              </div>
            </div>
            <ul
              class="a-list a-list--dot"
              *ngFor="let benefit of item.benefits"
            >
              <li>
                {{ benefit }}
              </li>
            </ul>
          </div>
        </div>
        <div fxFlex="20">
          <div class="action-container">
            <h5 class="plan-pricing">
              {{ item.price.currency }} {{ item.price.value }} /
              {{ item.price.frequency }}
            </h5>

            <button
              type="button"
              class="a-button a-button--secondary -fixed -without-icon subscribe-btn"
              (click)="selectPlan(item)"
            >
              <span class="a-button__label">Subscribe</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-error-message
  *ngIf="showNoStockErrorMessage$ | async"
  [errorMessage]="noStockErrorMessage"
  (closed)="showNoStockErrorMessage$.next(false)"
></app-error-message>

<app-choose-vehicle-dialog
  *ngIf="showVehicleSelectionDialog$ | async"
  [selectedPlan]="selectedPlan"
  [selectedService]="serviceDetails"
  (save)="onVehicleSelection($event)"
  (close)="showVehicleSelectionDialog$.next(false)"
>
</app-choose-vehicle-dialog>
