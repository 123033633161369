<div
  class="explore-service-section"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLaoyoutGap="16px"
>
  <h5 class="a-text">Discover services for your vehicle now</h5>
  <div>
    <app-button
      buttonLabel="View Service Catalog"
      icon="boschicon-bosch-ic-components"
      iconPlacement="left"
      theme="secondary"
      (clicked)="exploreButtonClick.emit(true)"
    >
    </app-button>
  </div>
</div>
